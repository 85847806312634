
import Vue from 'vue';
import {namespace} from 'vuex-class';
import {Component, Watch} from 'vue-property-decorator';
import {CONTAINER_STORE_NAME} from "@/store/container.store";
import {APPLICATION_STORE_NAME, ApplicationStoreGetters} from "@/store/application.store";
import Company from "@/models/Company";
import ContainerType from "@/models/ContainerType.model";
import {CONTAINER_TYPE_STORE_NAME, ContainerTypeStoreActions} from "@/store/container-type.store";
import {ContainerTypeStatus} from "@/enum/ContainerTypeStatus";

const ContainerStore = namespace(CONTAINER_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);
const ContainerTypeStore = namespace(CONTAINER_TYPE_STORE_NAME);

@Component({
  components: {
    ContainerListComponent: () => import(
      /* webpackChunkName: "ContainerListComponent" */
      '@/components/ContainerType/ContainerList.component.vue'
    ),
    EditContainerComponent: () => import(
      /* webpackChunkName: "EditContainerComponent" */
      '@/components/ContainerType/EditContainer.component.vue'
    ),
    DeleteContainerComponent: () => import(
      /* webpackChunkName: "ConfirmDeleteContainer" */
      '@/components/ContainerType/ConfirmDeleteContainer.component.vue'
    ),
  }
})
export default class ContainersOverviewView extends Vue {
  /**
   * Action to get the current selected Company
   */
  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_COMPANY)
  public company!: Company;

  @ContainerTypeStore.Action(ContainerTypeStoreActions.GET_ALL)
  private getAllContainerTypesAction!: (companyId: string) => Promise<ContainerType[]>;

  /**
   * Container Type Delete Action
   * @private
   */
  @ContainerTypeStore.Action(ContainerTypeStoreActions.DELETE)
  private deleteContainerTypesAction!: (containerTypeId: string) => Promise<boolean>;

  /**
   * list of all ContainerType that should be shown inside the view
   */
  public containerTypes: ContainerType[] = [];

  /**
   * list of all individual ContainerType that are requested
   */
  public requestedContainerTypes: ContainerType[] = [];

  /**
   * flag that indicates if the view is loading
   * @private
   */
  private isLoading: boolean = false;

  /**
   * flag that indicates if the create dialog should be shown
   * @private
   */
  private showCreationDialog: boolean = false;

  /**
   * flag that indicates if the edit dialog should be shown
   * @private
   */
  private showEditDialog: boolean = false;

  /**
   * flag that indicates if the delete dialog should be shown
   */
  private showDeleteDialog: boolean = false;

  /**
   * search query string, used for searching through the items
   * @private
   */
  private search: string = '';

  /**
   * container that was selected
   * @private
   */
  private selectedContainer: ContainerType | undefined;

  /**
   * flag that indicates which selection of items is shown
   * @private
   */
  private showProvedItems = false;

  private loadingDelete = false;

  /**
   * changes the selection to the one that is passed
   * @param value
   * @private
   */
  private onSelectionChange(value: boolean): void {
    this.showProvedItems = value;
  }

  async created() {
    this.isLoading = true;
    await this.loadTypes();
    this.isLoading = false;
  }

  /**
   * is called when the edit dialog button is pressed, opens the edit dialog with the data of the selected container
   */
  public startEditing(container: ContainerType) {
    this.showEditDialog = true;
    this.selectedContainer = container;
  }

  /**
   * is called when the delete button is pressed, opens confirm delete container and sets the selected container
   * @param container
   */
  public openConfirmDeleteDialog(container: ContainerType) {
    this.selectedContainer = container;
    this.showDeleteDialog = true;
  }

  /**
   * is called when the confirm dialog is closed
   * @param reload
   * @private
   */
  private async onDeleteContainerTypeConfirmed(reload: boolean) {
    if (reload) {
      try {
        this.loadingDelete = true;
        const result = await this.deleteContainerTypesAction(this.selectedContainer!.id!);
        if(result) this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.CONTAINER_TYPE_DELETED_SUCCESS');
        this.loadingDelete = false;
      } catch (e) {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    }

    await this.closeDialog(reload);
  }

  /**
   * is called when one of the dialog is closed, resets the modal flags and reloads data if needed
   * @param reload
   * @private
   */
  private async closeDialog(reload: boolean) {
    this.showCreationDialog = false;
    this.showEditDialog = false;
    this.showDeleteDialog = false;
    if (reload) {
      try {
        await this.loadTypes();
      } catch (e) {
        this.$handleError(e);
      }
    }
  }

  private async loadTypes() {
    try {
      const containers = await this.getAllContainerTypesAction(this.$route.params.id);
      this.containerTypes = containers.filter((c) => c.status === ContainerTypeStatus.NOT_REQUESTED || c.status === ContainerTypeStatus.ACCEPTED);
      this.requestedContainerTypes = containers.filter((c) => c.status === ContainerTypeStatus.DECLINED || c.status === ContainerTypeStatus.REQUESTED);
    } catch (_) {
      this.containerTypes = [];
      this.requestedContainerTypes = [];
    }
  }
}

